.wrapper {
  position: relative;
  text-align: center;
  background-color: rgb(179, 203, 203);
  width: 100%;
  height: 100vh;
  animation: fade-in 1s;
}

.under-construction {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  font-weight: bold;
  font-style: italic;
  color: rgb(220, 231, 231);
  transition: 1s;
  user-select: none;
  cursor: pointer;
  opacity: 0;
  animation: fade-in 1s 1s forwards;
}

.under-construction:hover {
  color: rgb(47, 160, 160);
  font-size: 1.95em;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
